import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";
import parse from 'html-react-parser';

const Page = () => {
  let { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const pageData = await axios.get(API_URL + 'page/' + slug + '/');
      setPage(pageData.data);
      setLoading(false);
    };
    fetchData();
  }, [slug]);

  return (
    <div id="content" className="container mt-5">
      <div className="row">
        {loading === false && (
          <Fragment>
            <div className="col-12 col-lg-8">
              <div className="bg-white p-3 rounded shadow-sm">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <h1>{page.title}</h1>
                </div>
                </div>
                <div className="row">
                  <div className="col">
                    {parse(page.content)}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
    </div>
    </div>

  )
}


export default Page;