import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";
import parse from 'html-react-parser';
import Moment from 'moment';

const Article = () => {
    let { slug } = useParams();
    const [loading, setLoading] = useState(true);
    const [article, setArticle] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const articleData = await axios.get(API_URL + 'articles/' + slug + '/');
            setArticle(articleData.data);
            setLoading(false);
        };
        fetchData();
    }, [slug]);

    return (
        <div className="container">
            <div className="col-sm">
                {loading === false && (
                    <Fragment>
                        <div key={article.id} className="content">
                            <p className="date">Published {Moment(article.created).format("MMM D, YYYY")}</p>
                            <h2>{article.title}</h2>
                            {parse(article.content)}
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    )
}


export default Article;