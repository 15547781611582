import React, { useState, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";
import Moment from 'moment';
import parse from 'html-react-parser';


const Index = () => {
    const [data, setData] = useState('');
    const [index, setIndex] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const dataData = await axios.get(API_URL + 'data/');
            const indexData = await axios.get(API_URL + 'page/index/');
            let data = JSON.parse(dataData.data);
            data.slice(0,5);
            setData(data);
            setIndex(indexData.data);
            setLoading(false);
        };
        fetchData();
    }, []);
    
    
    
    return (
        <div className="container">
            <div className="row">
                {loading === false && (
                    <Fragment>
                        <div className="col-8">{parse(index.content)}</div>
                        <div className="col-4">
                            {data.map((item) => {
                                const slug = item.slug;
                                return (
                                    <div key={item.title} className="article">
                                        {slug ? (
                                        <Link to={"/articles/" + item.slug + "/"}>
                                            <p className="date">Published {Moment(item.created).format("MMM D, YYYY")}</p>
                                            <h3>{item.title}</h3>
                                            <p>{item.excerpt}</p>
                                        </Link>
                                        ) : (
                                            <Fragment>
                                                <p className="date">Published {Moment(item.created).format("MMM D, YYYY")}</p>
                                                <h3>{item.title}</h3>
                                                <p>{item.excerpt}</p>
                                                <Link to={item.file} className="download"><img src="/static/img/pdf.svg" alt="download"/></Link>
                                            </Fragment>
                                        )}
                                        <div className="tags">
                                            {item.tags.map((tag) => {
                                                return (
                                                    <div className="tag" key={tag}>{tag}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
}

export default Index;
