import React, { Component } from "react";


class Header extends Component {

  render() {
    return (
      <header>
        <div className="container">
            <a className="ms-auto" href="/"><img src="/static/img/logo.svg" alt="logo"/></a>
        </div>
      </header>   
    );
  }
}

export default Header;