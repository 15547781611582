import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Article from "./components/Article";
import ArticleList from "./components/ArticleList";
import Header from "./components/Header";
import Index from "./components/Index";
import Page from "./components/Page";


class App extends Component {
  
  render() {
    return (
      <Router>
        <Header />
        {}
        <Routes>
          <Route index element={<Index />} />
          <Route path="/articles/:slug/" element={<Article />} />
          <Route path="/articles/" element={<ArticleList/>} />
          <Route path='/:slug/' element={<Page />} />
        </Routes>
      </Router>
    );
  }
}
export default App;