import React from 'react';
import { createRoot } from 'react-dom/client';
import './css/app.css';
import App from './App';
import Footer from "./components/Footer";
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
    <Footer />
  </React.StrictMode>
);
reportWebVitals();
